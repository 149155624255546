import React from 'react';

import { Button, Input } from 'antd';

import { login } from '../api/login';
import { useAuth } from '../hooks/useAuth';

const Auth = () => {
  const auth = useAuth();

  /**
   * Manual click enter button
   * @param {object} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData(e.currentTarget);

    login({
      login: formData.get('login'),
      pass: formData.get('pass'),
    }).then(({ err, data }) => {
      if (err === 0) {
        auth.login(data);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input type="text" name="login" />
      <Input type="password" name="pass" />
      <Button htmlType="submit">Войти</Button>
    </form>
  );
};

export default Auth;
