import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import Home from '../pages/Home';
import Auth from '../pages/Auth';
import Points from '../pages/Points';

import { AuthLayout } from '../layouts/AuthLayout';
import { LoginLayout } from '../layouts/LoginLayout';
import { ProtectedLayout } from '../layouts/ProtectedLayout';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />}>
      <Route path="/" element={<ProtectedLayout />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route path="/" element={<LoginLayout />}>
        <Route path="login" element={<Auth />} />
      </Route>
      <Route path="/" element={<ProtectedLayout />}>
        <Route path="points" element={<Points />} />
      </Route>
    </Route>
  )
);
