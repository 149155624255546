const API = 'https://sapi-test.zrp-service.site/v1';

/**
 * POST request
 * @param {string} url
 * @param {object} params
 * @returns
 */
const apiPost = async (url, params = {}) => {
  const response = await fetch(`${API}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(params),
  });

  if (response.ok) {
    return await response.json();
  }

  return false;
};

/**
 * Authorization
 * @param {object} params
 * @returns
 */
export const login = async (params) => {
  return await apiPost('/user/login', params);
};
